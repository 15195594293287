'use client'

import { useEffect } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import useFetch from 'hooks/useFetch'
import { useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { EMAIL_CODE_VERIFICATION_URL, USERS_VERIFICATION_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { VerificationMethods } from 'constants/verification'
import { dismissVerificationPrompt, getVerificationPrompt } from 'data/api'

import { clickEvent } from '_libs/common/event-tracker/events'
import { EmailCodeView } from '@marketplace-web/domain/verification'

const screenByEmailCodeView = {
  [EmailCodeView.EnterCode]: Screen.MandatoryEmailVerificationEnterCode,
  [EmailCodeView.NotReceiveEmail]: Screen.MandatoryEmailVerificationResendCode,
}

export const useEmailCodeTest = () => {
  const { user } = useSession()
  const { view: viewParam } = useLocation().searchParams
  const view = typeof viewParam === 'string' ? viewParam : EmailCodeView.EnterCode
  const { track } = useTracking()
  const {
    fetch: fetchPrompts,
    data: prompts,
    error,
    isLoading: isLoadingPrompts,
  } = useFetch(getVerificationPrompt)

  const { relativeUrl } = useLocation()
  const isEmailCodeMethod = prompts?.prompt.methods.includes(VerificationMethods.EmailCode)
  const isInVerificationFlow =
    relativeUrl === USERS_VERIFICATION_URL || relativeUrl === EMAIL_CODE_VERIFICATION_URL

  useEffect(() => {
    if (!isInVerificationFlow) return
    if (!user?.id) return

    fetchPrompts(user.id)
  }, [user, isInVerificationFlow, fetchPrompts])

  const trackEmailCodeSkipEvent = () => {
    if (error) return
    if (!isEmailCodeMethod) return
    if (prompts?.prompt.mandatory) return

    track(
      clickEvent({
        screen: Screen.MandatoryEmailVerificationStart,
        target: ClickableElement.Skip,
      }),
    )
  }

  const handleDismissVerificationPrompt = async () => {
    if (!user?.id) return
    if (error) return
    if (!isEmailCodeMethod) return
    if (prompts?.prompt.mandatory) return

    await dismissVerificationPrompt(user.id)
  }

  const trackLogoutEvent = () => {
    if (!isEmailCodeMethod) return

    const eventData = {
      screen: Screen.MandatoryEmailVerificationStart,
      target: ClickableElement.SessionsLogOut,
    }

    if (relativeUrl === EMAIL_CODE_VERIFICATION_URL) {
      eventData.screen = screenByEmailCodeView[view]
    }

    track(clickEvent(eventData))
  }

  return {
    trackLogoutEvent,
    trackEmailCodeSkipEvent,
    handleDismissVerificationPrompt,
    isLoadingPrompts,
  }
}
