'use client'

import { Button, Container, Spacer } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { UserModel } from 'types/models'
import { ITEM_UPLOAD_URL, GUIDE_ENTRIES_URL } from 'constants/routes'
import { urlWithParams } from '_libs/utils/url'
import { AccessChannel } from 'constants/index'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { useAuthModal } from '@marketplace-web/domain/identity'

type Props = {
  user: UserModel | null
}

const LoginSection = ({ user }: Props) => {
  const translate = useTranslate('header.side_bar')
  const { track } = useTracking()
  const { openAuthModal } = useAuthModal()

  const handleModalOpen = () => {
    openAuthModal()
    const trakingEvent = clickEvent({
      target: ClickableElement.Login,
    })

    track(trakingEvent)
  }

  const handleSellNow = () => {
    const trakingEvent = clickEvent({
      target: ClickableElement.UploadItem,
    })

    track(trakingEvent)
  }

  return (
    <Container>
      <SeparatedList separator={<Spacer />}>
        <Button
          text={translate('sell_now')}
          styling={Button.Styling.Filled}
          url={ITEM_UPLOAD_URL}
          onClick={handleSellNow}
          testId="side-bar-sell-btn"
        />
        {!user && (
          <Button
            text={translate('sign_in')}
            onClick={handleModalOpen}
            testId="side-bar-signin-btn"
          />
        )}
        <Button
          text={translate('guide')}
          styling={Button.Styling.Flat}
          testId="side-bar-guide-btn"
          url={urlWithParams(GUIDE_ENTRIES_URL, {
            access_channel: AccessChannel.VintedGuide,
          })}
        />
      </SeparatedList>
    </Container>
  )
}

export default LoginSection
