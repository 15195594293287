'use client'

import { Badge, Divider, Label } from '@vinted/web-ui'

import { useShouldShowDarkModeBanner } from '@marketplace-web/shared/dark-mode'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { AccountSwitch } from '@marketplace-web/domain/account-staff'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'

import {
  DONATIONS_SETTINGS_URL,
  GO_TO_WALLET_URL,
  MEMBER_PROFILE_URL,
  MY_ORDERS_URL,
  PROFILE_SETTINGS_URL,
  REFERRALS_URL,
  ROOT_URL,
  USER_PERSONALIZATION_SIZES_URL,
  WALLET_INVOICES_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { OrderType } from 'constants/my-orders'
import { logoutUser } from 'data/api/authentication/requests'
import { donationsClickEvent } from '_libs/common/event-tracker/events'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { useCookie } from '@marketplace-web/shared/cookies'

import AccountLink from './AccountLink'

const AccountLinks = () => {
  const translate = useTranslate()
  const { track } = useTracking()
  const cookies = useCookie()
  const { user } = useSession()
  const showFundraiser = !user?.business

  // TODO: 'payments' is a "special" (hard-coded) feature switch, which must be removed
  const isPaymentsEnabled = useFeatureSwitch('payments')
  const shouldShowMyOrdersNewBadge = useFeatureSwitch('my_orders_new_badge_web')
  const isReferralsEnabled = useFeatureSwitch('referrals')
  const shouldShowDarkModeAdoption = useShouldShowDarkModeBanner()
  const isImpersonatingBusiness = user?.impersonating_business
  const myOrdersDynamicDefaultTabAbTest = useAbTest('my_orders_dynamic_default_tab')

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) navigateToPage(ROOT_URL)
  }

  const handleFundraiserClick = () => {
    track(
      donationsClickEvent({
        target: ClickableElement.EnterDonationsFromWebMenu,
      }),
    )
  }

  const renderSettingsSuffix = () => {
    if (!shouldShowDarkModeAdoption) return null

    return <Badge theme="primary" content={translate('common.new_badge')} />
  }

  if (!user) return null

  return (
    <>
      <Label styling={Label.Styling.Wide} text={translate('header.user_menu.account.title')} />
      <SeparatedList separator={<Divider />}>
        <AccountLink
          title={translate('header.user_menu.account.items.profile.title')}
          url={MEMBER_PROFILE_URL(user.id)}
        />
        {!isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.settings.title')}
            url={PROFILE_SETTINGS_URL}
            suffix={renderSettingsSuffix()}
            testId="settings-account-link"
          />
        )}
        {!isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.personalization.title')}
            url={USER_PERSONALIZATION_SIZES_URL}
          />
        )}
        {isPaymentsEnabled && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.wallet.title')}
            url={GO_TO_WALLET_URL}
          />
        )}
        <AccountLink
          title={translate('header.user_menu.account.items.my_orders.title')}
          url={MY_ORDERS_URL({
            type: myOrdersDynamicDefaultTabAbTest?.variant === 'on' ? '' : OrderType.Sold,
            isDynamicTabMenuLink: !!myOrdersDynamicDefaultTabAbTest?.variant,
          })}
          suffix={
            shouldShowMyOrdersNewBadge && (
              <Badge theme="primary" content={translate('common.new_badge')} />
            )
          }
          testId="my-orders-account-link"
        />
        {showFundraiser && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.donations.title')}
            url={DONATIONS_SETTINGS_URL}
            onClick={handleFundraiserClick}
          />
        )}
        {!isPaymentsEnabled && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.invoices.title')}
            url={WALLET_INVOICES_URL}
          />
        )}
        {isReferralsEnabled && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.referrals.title')}
            url={REFERRALS_URL}
          />
        )}
        {user?.manageable_accounts?.length && <AccountSwitch />}
        <AccountLink
          title={translate('header.user_menu.account.items.logout.title')}
          onClick={handleLogoutClick}
        />
      </SeparatedList>
    </>
  )
}

export default AccountLinks
