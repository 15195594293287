'use client'

import { Button, Cell, Dialog, Icon, Spacer, Text } from '@vinted/web-ui'
import { Camera16 } from '@vinted/monochrome-icons'
import { ChangeEvent, useRef, useState } from 'react'

import { isResponseError } from '@marketplace-web/shared/api-client'
import { setSessionStorageItem, useWindowEvent } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { Dropzone } from '@marketplace-web/shared/ui-helpers'
import { urlWithParams } from '_libs/utils/url'
import * as api from 'data/api'

const SearchByImageModal = () => {
  const translate = useTranslate('searchbar.search_by_image.modal')

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const handleDrop = async (files: Array<File>) => {
    const file = files[0]
    if (!file || !file.type.startsWith('image')) return

    const formData = new FormData()
    formData.append('photo[type]', 'item')
    formData.append('photo[file]', file)

    setIsLoading(true)
    const response = await api.createPhoto(formData)
    setIsLoading(false)

    if (isResponseError(response)) {
      return
    }

    setSessionStorageItem('search_by_image_url', response.url)
    window.location.href = urlWithParams('/catalog', {
      search_by_image_uuid: response.temp_uuid,
    })
  }

  const handleChangeInput = (ev: ChangeEvent<HTMLInputElement>) => {
    if (!ev.target.files) return
    handleDrop(Array.from(ev.target.files))
  }

  const handleClickUpload = () => {
    inputRef.current?.click()
  }

  useWindowEvent('paste', async event => {
    if (!isOpen) return

    const items = event.clipboardData?.items
    if (!items) return

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const file = item.getAsFile()
      if (file && file.type.startsWith('image')) {
        // eslint-disable-next-line no-await-in-loop
        await handleDrop([file])

        return
      }
    }
  })

  return (
    <>
      <div className="u-flexbox u-justify-content-center u-align-items-center u-fill-height">
        <Button
          testId="search-by-image-button"
          onClick={handleOpen}
          size={Button.Size.Small}
          styling={Button.Styling.Flat}
          icon={<Icon name={Camera16} />}
        />
      </div>

      <Dialog show={isOpen} closeOnOverlay defaultCallback={handleClose}>
        <Cell>
          <Text as="h1" type={Text.Type.Title} text={translate('title')} />
          <Spacer />

          <Dropzone onDrop={handleDrop}>
            <Cell>
              <div className="u-flexbox u-justify-content-center u-padding-regular">
                <Button
                  isLoading={isLoading}
                  text={translate('upload')}
                  inline
                  onClick={handleClickUpload}
                />

                <input
                  className="u-hidden"
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleChangeInput}
                />
              </div>
            </Cell>
          </Dropzone>
        </Cell>
      </Dialog>
    </>
  )
}

export default SearchByImageModal
