'use client'

import { MouseEvent } from 'react'
import { Spacer } from '@vinted/web-ui'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useConsent } from '@marketplace-web/shared/consent'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { NAVIGATION_COOKIES_URL } from 'constants/catalog'
import {
  BUSINESS_TERMS_AND_CONDITIONS_URL,
  BUSINESS_TERMS_OF_SALE_URL,
  BUSINESS_TERMS_URL,
  COOKIE_POLICY_URL,
  HELP_SAFETY_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { toggleOneTrustInfoDisplay } from '_libs/utils/window'
import { clickEvent } from '_libs/common/event-tracker/events'

import LinkCell from '../LinkCell'
import LinksGroup from '../LinksGroup'

type Props = {
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
}

const SecondColumn = ({ impressumUrl, isBusinessAccountLinksVisible }: Props) => {
  const translate = useTranslate('header.main_navigation.about')
  const { track } = useTracking()

  const isProTermsAndConditionsFSEnabled = useFeatureSwitch('pro_terms_and_conditions_enabled')

  const { isCookieConsentVersion } = useConsent()

  const handleCookiesClick = (event: MouseEvent) => {
    event.preventDefault()
    toggleOneTrustInfoDisplay()

    track(
      clickEvent({
        target: ClickableElement.CookieSettings,
        screen: Screen.AboutMenu,
      }),
    )
  }

  const renderBusinessAccountLinks = () => {
    if (!isBusinessAccountLinksVisible) return null

    if (isProTermsAndConditionsFSEnabled) {
      return (
        <LinkCell
          title={translate('policies.items.pro_terms_and_conditions')}
          url={BUSINESS_TERMS_AND_CONDITIONS_URL}
        />
      )
    }

    return (
      <>
        <LinkCell
          title={translate('policies.items.pro_terms_of_sale')}
          url={BUSINESS_TERMS_OF_SALE_URL}
        />
        <LinkCell title={translate('policies.items.pro_terms_of_use')} url={BUSINESS_TERMS_URL} />
      </>
    )
  }

  return (
    <div>
      <LinksGroup title={translate('policies.title')}>
        <LinkCell title={translate('policies.items.safety')} url={HELP_SAFETY_URL} />
        {impressumUrl && (
          <LinkCell title={translate('policies.items.impressum')} url={impressumUrl} />
        )}
        <LinkCell title={translate('policies.items.privacy_policy')} url={PRIVACY_POLICY_URL} />
        <LinkCell title={translate('policies.items.terms_and_conditions')} url={TERMS_URL} />
        <LinkCell title={translate('policies.items.cookie_policy')} url={COOKIE_POLICY_URL} />
        {renderBusinessAccountLinks()}
      </LinksGroup>
      <Spacer size={Spacer.Size.X2Large} />
      {isCookieConsentVersion && (
        <LinksGroup title={translate('privacy.title')}>
          <LinkCell
            title={translate('privacy.items.cookies')}
            url={NAVIGATION_COOKIES_URL}
            onClick={handleCookiesClick}
            testId="about-panel-cookie-settings-button"
          />
        </LinksGroup>
      )}
    </div>
  )
}

export default SecondColumn
