'use client'

import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { Card, Divider, Icon, InputBar } from '@vinted/web-ui'
import { Search16 } from '@vinted/monochrome-icons'
import { isEmpty, trim } from 'lodash'
import uuid from 'uuid'

import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { redirectToPage, useLocation } from '@marketplace-web/shared/browser'
import { cookiesDataByName, useCookie } from '@marketplace-web/shared/cookies'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { ListNavigator, OutsideClick } from '@marketplace-web/shared/ui-helpers'
import { MS_PER_SECOND, SECONDS_PER_DAY } from '@marketplace-web/shared/utils'

import { logSavedCategoryEvent } from '_libs/common/braze/utils/event-loggers'
import {
  clickEvent,
  searchSuggestionAutofillTextSubmittedEvent,
  selectSavedSearchEvent,
  selectSearchSuggestionEvent,
  SelectSearchSuggestionEventArgs,
} from '_libs/common/event-tracker/events'
import {
  getSearchSessionData,
  searchSuggestionToUrlParams,
  setSearchStartData,
} from '_libs/utils/search'
import { normalizedQueryParam, toUrlQuery, urlWithParams } from '_libs/utils/url'
import { CATALOG_URL, HELP_SEARCH_URL, USER_SEARCH_URL } from 'constants/routes'
import { SearchBarSearchType, SearchSuggestionType } from 'constants/search'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { SavedSearchType, SearchStartType } from 'constants/tracking/search'
import useBackButtonAbTest from 'hooks/useBackButtonAbTest'
import useSystemConfiguration from 'hooks/useSystemConfiguration/useSystemConfiguration'
import { CatalogDto, SavedSearchDto } from 'types/dtos'
import { SearchSuggestionModel } from 'types/models'

import TypeSelector from './TypeSelector'
import SearchSuggestions, { useSearchSuggestions } from './SearchSuggestions'
import { useSavedSearchesContext } from '../SavedSearchesProvider'
import useSelectedCatalogs from './useSelectedCatalogs'
import SearchByImageModal from './SearchByImageModal'
import useIsSearchByImageEnabled from './useIsSearchByImageEnabled'
import useSavedSearchesSubscribeEducation from '../SavedSearches/SearchSubscribeModal/useSavedSearchesSubscribeEducation'
import { SavedSearchesList, SearchSubscribeModal } from '../SavedSearches'
import { searchDtoToUrlParams } from '../SavedSearchesProvider/transformers'

const SEARCH_URLS: Record<SearchBarSearchType, string> = {
  [SearchBarSearchType.Item]: CATALOG_URL,
  [SearchBarSearchType.User]: USER_SEARCH_URL,
  [SearchBarSearchType.Faq]: HELP_SEARCH_URL,
}

const MAX_QUERY_LENGTH = 5_000
const SEARCH_SUGGESTION_MIN_QUERY_LENGTH = 1

const isSavedSearchesRendered = (query: string) =>
  isEmpty(query) || query.length < SEARCH_SUGGESTION_MIN_QUERY_LENGTH

type Props = {
  searchType?: SearchBarSearchType
  isInCatalog: boolean
  catalogTree: Array<CatalogDto>
}

const SearchBar = ({
  searchType: initialSearchType = SearchBarSearchType.Item,
  isInCatalog = false,
  catalogTree,
}: Props) => {
  const { searchParams } = useLocation()
  const translate = useTranslate()
  const { track } = useTracking()
  const cookies = useCookie()

  const { user } = useSession()
  const systemConfiguration = useSystemConfiguration()

  const userId = user?.id
  const userCountryCode = user?.country_code
  const countryCode = systemConfiguration?.userCountry

  const {
    showSearchSubscriptionEducation,
    closeSearchSubscriptionEducation,
    isSubscribedModalOpen,
  } = useSavedSearchesSubscribeEducation()

  const { searches, actions } = useSavedSearchesContext()

  const savedRecentSearchListId = useRef('')

  const selectedCatalogs = useSelectedCatalogs(catalogTree)

  const { historyMethod } = useBackButtonAbTest()

  const holdout = useAbTest('buyer_domain_holdout_2025q1')?.variant !== 'off'

  const suggestionAutofillAb = useAbTest('search_suggestions_autofill_v3')
  const isSuggestionAutofillEnabled = Boolean(
    suggestionAutofillAb && holdout && suggestionAutofillAb.variant !== 'off',
  )

  const isSearchByImageEnabled = useIsSearchByImageEnabled()

  const initialQuery = normalizedQueryParam(searchParams.search_text)

  const [query, setQuery] = useState(initialQuery)
  const [searchType, setSearchType] = useState(initialSearchType)
  const [isFocused, setIsFocused] = useState(false)
  const [isTypeSelectorOpen, setIsTypeSelectorOpen] = useState(false)
  const [highlightedSearchText, setHighlightedSearchText] = useState('')

  const { fetchSuggestions, clearSuggestions, suggestionsListId, suggestions } =
    useSearchSuggestions(query)

  const inputRef = useRef<HTMLInputElement>(null)
  const isSavedSearchesFetched = useRef(false)

  const suggestionsSessionId = useRef(uuid.v4())
  const savedRecentSearchSessionId = useRef(uuid.v4())

  const seenSuggestions = useRef<Set<number>>(new Set())
  const seenRecentSearches = useRef<Set<number>>(new Set())
  const sentSelectSearchIds = useRef<Set<number>>(new Set())

  useEffect(() => {
    if (isFocused) return

    suggestionsSessionId.current = uuid.v4()
    savedRecentSearchSessionId.current = uuid.v4()
    seenSuggestions.current.clear()
    seenRecentSearches.current.clear()

    savedRecentSearchListId.current = uuid.v4()
  }, [isFocused])

  const requestSearches = () => {
    actions.fetchSearches()
    isSavedSearchesFetched.current = true
  }

  const querySearchSuggestions = (queryInput: string) => {
    if (searchType !== SearchBarSearchType.Item || isEmpty(queryInput)) return

    if (!isSavedSearchesRendered(queryInput)) {
      fetchSuggestions(queryInput)
    }
  }

  const focusInput = () => inputRef.current?.focus()

  const blurInput = () => inputRef.current?.blur()

  const toggleTypeSelector = () => setIsTypeSelectorOpen(prevState => !prevState)

  const searchSuggestionUrl = (suggestion: SearchSuggestionModel) => {
    const { item: itemSearchUrl } = SEARCH_URLS

    if (isInCatalog) return undefined

    if (suggestion.type === SearchSuggestionType.Scoped) {
      const filterQuery = toUrlQuery(searchSuggestionToUrlParams(suggestion))

      return `${itemSearchUrl}?${filterQuery}`
    }

    return `${itemSearchUrl}?search_text=${encodeURIComponent(suggestion.query)}`
  }

  const getPlaceholder = () => {
    const isCatalogPlaceholder = isInCatalog && selectedCatalogs.length

    if (searchType === SearchBarSearchType.Item && isCatalogPlaceholder) {
      const catalogName = selectedCatalogs.map(catalog => catalog.title).join(', ')

      return translate(`searchbar.placeholder.${searchType}_with_catalog_selected`, {
        catalog: catalogName,
      })
    }

    return translate(`searchbar.placeholder.${searchType}`)
  }

  const handleQueryChange = (
    event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
    resetHighlight: () => void,
  ) => {
    resetHighlight()
    setHighlightedSearchText('')

    const newQuery = event.currentTarget.value

    if (!isEmpty(trim(newQuery))) {
      querySearchSuggestions(newQuery)
    }

    setQuery(newQuery)
  }

  const handleValueClear = () => setQuery('')

  const handleTypeSelectorClick = () => {
    setIsFocused(false)
    toggleTypeSelector()

    track(clickEvent({ target: ClickableElement.SearchBarType }))
  }

  const handleTypeSelectorSelect = (newSearchType: SearchBarSearchType) => {
    toggleTypeSelector()
    focusInput()

    if (searchType !== newSearchType) setSearchType(newSearchType)
  }

  const handleOutClick = () => {
    // Prevent excessive re-rendering
    if (isSubscribedModalOpen || !(isTypeSelectorOpen || isFocused)) return

    setIsTypeSelectorOpen(false)
    setIsFocused(false)
  }

  const handleInputFocus = () => {
    if (!isSavedSearchesFetched.current) requestSearches()

    if (!isSavedSearchesRendered(query) && !isFocused) {
      querySearchSuggestions(query)
    }

    setIsTypeSelectorOpen(false)
    setIsFocused(true)
  }

  const handleInputClick = () => track(clickEvent({ target: ClickableElement.SearchBar }))

  const handleSubscribeClick = (_index: number, search: SavedSearchDto) => {
    if (!userId) return

    if (!search.subscribed) {
      showSearchSubscriptionEducation()

      logSavedCategoryEvent(
        search.catalog_id,
        user.external_id,
        catalogTree,
        search.brands?.map(brand => brand.id),
      )
    }
  }

  const handleSubscribeModalClose = () => {
    closeSearchSubscriptionEducation()
  }

  const handleSuggestionClick = (
    suggestion: SearchSuggestionModel,
    index: number,
    scopedSuggestionCount: number,
  ) => {
    const { searchSessionId, globalSearchSessionId } = getSearchSessionData()

    const trackingArgs: SelectSearchSuggestionEventArgs = {
      suggestions: suggestions.map(item => item.query),
      suggestion: suggestion.query,
      query,
      scopedSuggestionCount,
      index,
      countryCode: userCountryCode || countryCode,
      searchSessionId: searchSessionId || '',
      globalSearchSessionId,
      suggestionsSessionId: suggestionsSessionId.current,
      isFrontendGeneratedSuggestion: suggestion.type === SearchSuggestionType.Fallback,
    }

    if (suggestion.type === SearchSuggestionType.Scoped) {
      trackingArgs.subtitle = suggestion.subtitle
    }

    if ('params' in suggestion) {
      trackingArgs.params = suggestion.params
    }

    trackingArgs.suggestionPosition = index + 1
    trackingArgs.suggestionsListId = suggestionsListId.current

    track(selectSearchSuggestionEvent(trackingArgs))

    setSearchStartData({
      searchStartId: suggestionsSessionId.current,
      searchStartType: SearchStartType.SearchSuggestions,
    })

    if (isInCatalog) {
      const updatedUrl = urlWithParams(window.location.href, {
        search_text: suggestion.query,
        catalog_ids:
          suggestion.type === SearchSuggestionType.Scoped
            ? suggestion.filters.catalogIds
            : undefined,
      })

      window.history[historyMethod](null, '', updatedUrl)

      if (suggestion.type === SearchSuggestionType.Scoped) {
        window.location.reload()
      }
    }

    setQuery(suggestion.query)
    setIsFocused(false)
  }

  const handleSavedSearchClick = (index: number, savedSearch: SavedSearchDto) => {
    const { searchSessionId, globalSearchSessionId } = getSearchSessionData()

    const savedSearchType = savedSearch.subscribed
      ? SavedSearchType.SubscribedSearch
      : SavedSearchType.RecentSearch

    const searchStartType = savedSearch.subscribed
      ? SearchStartType.SubscribedSearch
      : SearchStartType.RecentSearch

    setSearchStartData({
      searchStartId: savedRecentSearchSessionId.current,
      searchStartType,
    })

    if (sentSelectSearchIds.current.has(savedSearch.id)) return
    sentSelectSearchIds.current.add(savedSearch.id)

    track(
      selectSavedSearchEvent({
        savedRecentSearchListId: savedRecentSearchListId.current,
        savedRecentSearchSessionId: savedRecentSearchSessionId.current,
        screen: Screen.SearchItems,
        position: index + 1,
        searchSessionId: searchSessionId || '',
        type: savedSearchType,
        searchTitle: savedSearch.title,
        globalSearchSessionId,
        newItemsCount: savedSearch.new_items_count,
        unrestrictedNewItemsCount: savedSearch.unrestricted_new_items_count,
      }),
    )
  }

  const handleSubmit = (event: FormEvent) => {
    if (searchType === SearchBarSearchType.Faq) {
      const expirationDate = new Date()
      expirationDate.setTime(expirationDate.getTime() + SECONDS_PER_DAY * MS_PER_SECOND)

      cookies.set(cookiesDataByName.help_center_search_session_id, uuid.v4())
    }

    if (searchType !== SearchBarSearchType.Item) return
    if (isInCatalog) {
      event.preventDefault()
      window.history[historyMethod](
        null,
        '',
        urlWithParams(window.location.href, { search_text: query }),
      )
    }

    setSearchStartData({
      searchStartId: suggestionsSessionId.current,
      searchStartType: SearchStartType.SearchManual,
    })

    blurInput()
    setIsFocused(false)
  }

  const handleSavedSearchOnEnter = (highlightedResultIndex: number) => {
    const highlightedSearch = searches[highlightedResultIndex]!
    const params = { ...searchDtoToUrlParams(highlightedSearch), search_id: highlightedSearch.id }
    const url = `${SEARCH_URLS[SearchBarSearchType.Item]}?${toUrlQuery(params)}`

    handleSavedSearchClick(highlightedResultIndex, highlightedSearch)
    redirectToPage(url)
    blurInput()
  }

  const handleSearchSuggestionOnEnter = (highlightedResultIndex: number) => {
    const highlightedSuggestion = suggestions[highlightedResultIndex]!
    const url = searchSuggestionUrl(highlightedSuggestion)
    const scopedSuggestions = suggestions.filter(item => item.type === SearchSuggestionType.Scoped)

    handleSuggestionClick(highlightedSuggestion, highlightedResultIndex, scopedSuggestions.length)

    if (isSuggestionAutofillEnabled) {
      track(
        searchSuggestionAutofillTextSubmittedEvent({
          query,
          suggestionsSessionId: suggestionsSessionId.current,
          suggestionsListId: suggestionsListId.current,
          suggestionText: highlightedSuggestion.title,
          params: 'params' in highlightedSuggestion ? highlightedSuggestion.params || null : null,
        }),
      )
    }

    if (url) redirectToPage(url)

    blurInput()
  }

  const handleSuggestionNavigation = (highlightedResultIndex: number | null) => {
    if (highlightedResultIndex === null) {
      setHighlightedSearchText('')
    } else if (isSavedSearchesRendered(query)) {
      setHighlightedSearchText(searches[highlightedResultIndex]!.search_text || '')
    } else {
      setHighlightedSearchText(suggestions[highlightedResultIndex]!.query)
    }
  }

  const renderIcon = () => (
    <div className="u-flexbox u-align-items-center">
      <Icon name={Search16} color={Icon.Color.GreyscaleLevel2} />
    </div>
  )

  const renderPrefix = () => (
    <div className="u-flexbox u-fill-height">
      <TypeSelector
        selectedType={searchType}
        isOpen={isTypeSelectorOpen}
        onClick={handleTypeSelectorClick}
        onSelect={handleTypeSelectorSelect}
      />
      <Divider orientation={Divider.Orientation.Vertical} />
    </div>
  )

  const handleMobileAutofill = (value: string) => {
    setHighlightedSearchText('')
    setQuery(value)
    querySearchSuggestions(value)
    inputRef.current?.focus()
  }

  const renderSuggestions = (highlightedResultIndex: number | null) => (
    <SearchSuggestions
      onMobileAutofill={handleMobileAutofill}
      query={query}
      items={suggestions}
      highlightedIndex={highlightedResultIndex}
      onSuggestionClick={handleSuggestionClick}
      suggestionUrl={searchSuggestionUrl}
      suggestionsListId={suggestionsListId}
      suggestionsSessionId={suggestionsSessionId}
      seenSuggestions={seenSuggestions}
      onClearSuggestions={clearSuggestions}
    />
  )

  const renderSavedSearches = (highlightedResultIndex: number | null) => {
    if (!userId || !searches.length) return null

    return (
      <SavedSearchesList
        savedRecentSearchListId={savedRecentSearchListId}
        highlightedIndex={highlightedResultIndex}
        onSubscribeClick={handleSubscribeClick}
        searchUrl={SEARCH_URLS[SearchBarSearchType.Item]}
        onSearchClick={handleSavedSearchClick}
        savedRecentSearchSessionId={savedRecentSearchSessionId}
        seenRecentSearches={seenRecentSearches}
      />
    )
  }

  const renderDropdown = (highlightedResultIndex: number | null) => {
    if (searchType !== SearchBarSearchType.Item || !isFocused) return null

    const dropdownContent = isSavedSearchesRendered(query)
      ? renderSavedSearches(highlightedResultIndex)
      : renderSuggestions(highlightedResultIndex)

    if (isEmpty(dropdownContent)) return null

    return (
      <div className="u-ui-margin-top-regular u-position-absolute u-fill-width">
        <Card styling={Card.Styling.Lifted}>
          <div className="u-overflow-hidden">{dropdownContent}</div>
        </Card>
      </div>
    )
  }

  const renderAccessChannel = () => {
    if (searchType !== 'faq') return null

    return <input type="hidden" name="access_channel" value="hc_search" />
  }

  let resultsCount = searches.length
  let onSelect = handleSavedSearchOnEnter

  if (!isSavedSearchesRendered(query)) {
    resultsCount = suggestions.length
    onSelect = handleSearchSuggestionOnEnter
  }

  return (
    <OutsideClick onOutsideClick={handleOutClick}>
      <form onSubmit={handleSubmit} method="get" action={SEARCH_URLS[searchType]}>
        {renderAccessChannel()}
        <ListNavigator
          itemCount={resultsCount}
          onSelect={onSelect}
          onHighlightedResultChange={
            isSuggestionAutofillEnabled ? handleSuggestionNavigation : undefined
          }
          resetOnUnknownKey={false}
        >
          {({ highlightedResultIndex, handleKeyNavigation, resetHighlightedResult }) => (
            <div className="u-position-relative">
              <InputBar
                suffix={isSearchByImageEnabled && <SearchByImageModal />}
                maxLength={MAX_QUERY_LENGTH}
                inputAria={{
                  'aria-labelledby': `search-${searchType}`,
                }}
                clearButtonAria={{
                  'aria-label': translate('searchbar.button.clear'),
                }}
                name="search_text"
                placeholder={getPlaceholder()}
                value={isSuggestionAutofillEnabled ? highlightedSearchText || query : query}
                icon={renderIcon()}
                prefix={renderPrefix()}
                onChange={event => handleQueryChange(event, resetHighlightedResult)}
                onFocus={handleInputFocus}
                onKeyDown={handleKeyNavigation}
                onValueClear={handleValueClear}
                onInputClick={handleInputClick}
                ref={inputRef}
                testId="search-text"
              />

              {renderDropdown(highlightedResultIndex)}
            </div>
          )}
        </ListNavigator>

        <SearchSubscribeModal isOpen={isSubscribedModalOpen} onClose={handleSubscribeModalClose} />
      </form>
    </OutsideClick>
  )
}

export default SearchBar
