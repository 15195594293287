'use client'

import { useState } from 'react'
import { Text, Cell, Image } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import { FormattedRelativeTime } from '@marketplace-web/shared/i18n'
import useAsset from 'hooks/useAsset'
import { GenericInboxNotificationModel } from 'types/models'
import { BRAZE_VINTED_LOGO_IMAGE_PATH } from '_libs/common/braze/constants'
import { InboxNotificationType } from 'constants/inbox-notification'

type Props = {
  notification: GenericInboxNotificationModel
  onClick: () => void
  onEnter: () => void
  defaultHighlighted?: boolean
}

const InboxNotificationItem = ({ notification, onClick, onEnter, defaultHighlighted }: Props) => {
  // we are using `useState` to protect `isHighlighted` from unwanted `defaultHighlighted` changes
  // so the setter is unused
  // eslint-disable-next-line react/hook-use-state
  const [isHighlighted] = useState(defaultHighlighted)
  const asset = useAsset()
  const { ref } = useInView({
    onChange: inView => inView && onEnter(),
  })

  const renderImage = () => {
    const fallbackImage =
      notification.type === InboxNotificationType.Braze
        ? asset(BRAZE_VINTED_LOGO_IMAGE_PATH)
        : undefined

    return (
      <Image
        role="img"
        src={notification.photoUrl || fallbackImage}
        fallbackSrc={fallbackImage}
        size={Image.Size.Large}
        styling={notification.photoStyle} // TODO: check if we can use single style, rather than dynamic
      />
    )
  }

  const renderBody = () => (
    <>
      <Text as="span" text={notification.body} html width={Text.Width.Parent} />
      {!!notification.note && (
        <Text
          as="h3"
          type={Text.Type.Subtitle}
          width={Text.Width.Parent}
          text={notification.note}
          html
        /> // TODO: check if this is needed
      )}
      <Text
        as="h3"
        text={<FormattedRelativeTime value={notification.time} />}
        type={Text.Type.Subtitle}
        width={Text.Width.Parent}
      />
    </>
  )

  return (
    <div ref={ref} data-testid="inbox-notification-intersection-wrapper">
      <Cell
        type={notification.link ? Cell.Type.Navigating : undefined}
        onClick={onClick}
        highlighted={isHighlighted}
        prefix={renderImage()}
        body={renderBody()}
        url={notification.link || undefined}
        testId={`user-notification-item${isHighlighted ? '-highlighted' : ''}`}
      />
    </div>
  )
}

export default InboxNotificationItem
