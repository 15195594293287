import { useEffect, useMemo, useRef, useState } from 'react'
import uuid from 'uuid'

import * as api from 'data/api'
import useFetch from 'hooks/useFetch'
import { transformSearchSuggestions } from 'data/transformers/search-suggestion'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { SearchSuggestionModel } from 'types/models'
import { SearchSuggestionType } from 'constants/search'
import { SearchFallbackSuggestionModel } from 'types/models/search-suggestion'
import useDebounce from 'hooks/useDebounce'

const SEARCH_SUGGESTION_FALLBACK_ID = -1
export const SEARCH_SUGGESTION_DEBOUNCE = 100

export const buildFallbackSuggestion = (
  title: string,
  query: string,
): SearchFallbackSuggestionModel => ({
  id: SEARCH_SUGGESTION_FALLBACK_ID,
  title,
  query,
  type: SearchSuggestionType.Fallback,
})

export function useSearchSuggestions(query: string) {
  const translate = useTranslate()

  const suggestionsListId = useRef<string | null>(null)

  const [suggestions, setSuggestions] = useState<Array<SearchSuggestionModel>>([])
  const [fetched, setFetched] = useState(false)

  const { data, fetch } = useFetch(api.getSearchSuggestions)

  useEffect(() => {
    if (!data) return
    setSuggestions(transformSearchSuggestions(data.search_suggestions))
  }, [data])

  const clearSuggestions = () => {
    setSuggestions([])
    setFetched(false)
    suggestionsListId.current = null
  }

  const fetchSuggestions = useDebounce(
    async (latestQuery: string) => {
      await fetch({ query: latestQuery })

      suggestionsListId.current = uuid.v4()
      setFetched(true)
    },
    SEARCH_SUGGESTION_DEBOUNCE,
    false,
  )

  const transformed = useMemo<Array<SearchSuggestionModel>>(() => {
    if (!fetched) return []

    const title = translate('search_suggestions.suggestion_fallback', { query })

    return [...suggestions, buildFallbackSuggestion(title, query)]
  }, [fetched, query, suggestions, translate])

  return {
    suggestionsListId,
    clearSuggestions,
    fetchSuggestions,
    suggestions: transformed,
  }
}
